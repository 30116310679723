<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">排查统计</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<div class="label ">单位：</div>
					<el-select @change="handleFirstChange" v-model="search.first_id" placeholder="请选择支队">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in firstList" :key="item.unit_id" :label="item.unit_name"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select @change="handleSecondChange" v-model="search.second_id" placeholder="请选择大队">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in secondList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select @change="handleThirdChange" v-model="search.third_id" placeholder="请请选择街道">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in thirdList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<div class="label ml-3">网格：</div>
					<el-select v-model="search.reseau_id" placeholder="请请选择网格">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in reseauList" :label="item.name" :key="item.reseau_id"
							:value="item.reseau_id">
						</el-option>
					</el-select>
				</div>
				<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search"></el-button>
				<el-button @click="handleExport" class="ml-2" type="primary" size="mini">导出</el-button>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%" :default-expand-all="true">
			</el-table>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				lists: [],
				firstList: [],
				secondList: [],
				thirdList: [],
				reseauList: [],
				search: {
					s: 'store/reseau.check/check_statistics',
					page: 1,
					first_id: 0,
					second_id: 0,
					third_id: 0,
					reseau_id: 0,
				},
			}
		},
		created() {
			this.getList();
			this.getReseauList();
			this.getUnitList();
		},
		methods: {
			handleExport() {
				let params = {
					s: 'store/reseau.check/check_statistics_export',
					first_id: this.search.first_id,
					second_id: this.search.second_id,
					third_id: this.search.third_id,
					reseau_id: this.search.reseau_id,
				};
				this.axios({
					token: true,
					params: params,
					method: 'get',
				}).then(res => {
					if (res.data.code == 1) {
						window.location.href = this.$conf.base_url + 'check_statistics_export.xls'
					}
				}).catch(err => {})
			},
			handleFirstChange(first_id) {
				this.search.second_id = 0;
				this.search.third_id = 0;
				for (let i = 0; i < this.firstList.length; i++) {
					if (this.firstList[i]['unit_id'] == first_id) {
						this.secondList = this.firstList[i].child;
					}
				}
				this.getReseauList();
			},
			handleSecondChange(second_id) {
				this.search.third_id = 0;
				for (let i = 0; i < this.secondList.length; i++) {
					if (this.secondList[i]['unit_id'] == second_id) {
						this.thirdList = this.secondList[i].child;
					}
				}
				this.getReseauList();
			},
			handleThirdChange(third_id) {
				this.getReseauList();
			},
			getUnitList() {
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.firstList = res.data.data

				}).catch(err => {});
			},
			getReseauList() {
				this.axios({
					token: true,
					params: {
						s: 'store/reseau.check/reseauLists',
						first_id: this.search.first_id,
						second_id: this.search.second_id,
						third_id: this.search.third_id,
					},
					method: 'get',
				}).then(res => {
					this.reseauList = res.data.data
				}).catch(err => {});
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
		},
	}
</script>